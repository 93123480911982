import { DeviceProfile, getDeviceProfile, View } from "@novorender/api";
import { ObjectDB, ObjectId } from "@novorender/data-js-api";
import { getGPUTier } from "detect-gpu";
import { ReadonlyVec3 } from "gl-matrix";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useLazyGetModelTreePermissionsQuery, useLazyGetProjectQuery } from "apis/dataV2/dataV2Api";
import { Permission } from "apis/dataV2/permissions";
import { ProjectInfo } from "apis/dataV2/projectTypes";
import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { explorerGlobalsActions, useExplorerGlobals } from "contexts/explorerGlobals";
import {
    HighlightCollection,
    highlightCollectionsActions,
    useDispatchHighlightCollections,
} from "contexts/highlightCollections";
import { highlightActions, useDispatchHighlighted } from "contexts/highlighted";
import { GroupStatus, ObjectGroup, objectGroupsActions, useDispatchObjectGroups } from "contexts/objectGroups";
import { useSetupVersionComparisonPropertyTree } from "features/propertyTree/hooks/useSetupVersionComparisonPropertyTree";
import { useFillGroupIds } from "hooks/useFillGroupIds";
import { useSceneId } from "hooks/useSceneId";
import { explorerActions, ProjectType, selectConfig } from "slices/explorer";
import { AsyncStatus } from "types/misc";
import { AppObjectDB } from "utils/appObjectDb";
import { VecRGBA } from "utils/color";
import { mixpanel } from "utils/mixpanel";
import { iterateAsync } from "utils/search";
import { sleep } from "utils/time";

import { renderActions } from "../renderSlice";
import { ErrorKind } from "../sceneError";
import { DEFAULT_CAMERA_PINHOLE_FAR, getDefaultCamera, loadScene, tm2LatLon } from "../utils";
import { useCachedCheckPermissionsQuery } from "./useCachedLazyCheckPermissionsQuery";

export function useHandleInit() {
    const sceneId = useSceneId();
    const config = useAppSelector(selectConfig);
    const dispatchHighlighted = useDispatchHighlighted();
    const dispatchHighlightCollections = useDispatchHighlightCollections();
    const dispatchObjectGroups = useDispatchObjectGroups();
    const {
        state: { canvas },
        dispatch: dispatchGlobals,
    } = useExplorerGlobals();
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [getProject] = useLazyGetProjectQuery();
    const [getModelTreePermissions] = useLazyGetModelTreePermissionsQuery();
    const checkPermissions = useCachedCheckPermissionsQuery();
    const fillGroupIds = useFillGroupIds();
    const setupVersionComparisonPropertyTree = useSetupVersionComparisonPropertyTree();

    const initialized = useRef(false);

    useEffect(() => {
        initView();

        async function initView() {
            if (initialized.current || !canvas) {
                return;
            }

            initialized.current = true;
            dispatch(renderActions.setSceneStatus({ status: AsyncStatus.Loading }));

            const detectedTier = await loadDeviceTier();
            const storedDeviceProfile = getStoredDeviceProfile();
            const deviceProfile = storedDeviceProfile
                ? { ...storedDeviceProfile, isMobile: detectedTier.isMobile }
                : {
                      ...detectedTier,
                      ...(detectedTier.tier >= 0
                          ? getDeviceProfile(detectedTier.tier as DeviceProfile["tier"])
                          : getDeviceProfile(0)),
                  };

            const view = await createView(canvas, { deviceProfile });

            try {
                const [{ url: _url, db, ...sceneData }, sceneCamera] = await loadScene(sceneId);
                const { projectId } = sceneData;

                const url = new URL(_url);
                const parentSceneId = url.pathname.replace(/^\//, "").replace(/\/$/, "");
                const isVersionComparison = parentSceneId.includes("/version-comparison/");

                const [projectV2, availableResourcePaths] = await Promise.all([
                    getProject({ projectId: sceneId })
                        .unwrap()
                        .catch((e) => {
                            if (e.status === 401 || e.status === 403) {
                                throw { error: "Not authorized", statusCode: e.status };
                            }
                            throw e;
                        }),
                    getModelTreePermissions({ projectId: isVersionComparison ? sceneData.projectId : sceneId }, true)
                        .unwrap()
                        .then((permissions) => {
                            return permissions
                                .filter(
                                    (p) =>
                                        p.path &&
                                        p.permissionIds?.some(
                                            (p) => p === Permission.SceneView || p === Permission.Scene,
                                        ),
                                )
                                .map((p) => p.path as string);
                        }),
                ]);

                const projectIsV2 = Boolean(projectV2);
                url.pathname = "";
                const octreeSceneConfig = await view.loadScene(
                    url,
                    parentSceneId,
                    "index.json",
                    new AbortController().signal,
                );

                const [tmZoneForCalc, permissions, availableAndForbidden] = await Promise.all([
                    loadTmZoneForCalc(projectV2, sceneData.tmZone, octreeSceneConfig.center),
                    checkPermissions({
                        scope: {
                            organizationId: sceneData.organization,
                            projectId,
                            viewerSceneId: sceneId === projectId || isVersionComparison ? undefined : sceneId,
                        },
                        permissions: Object.values(Permission),
                    }),
                    db && availableResourcePaths.length
                        ? getAvailableAndForbiddenObjectIds(db, availableResourcePaths)
                        : undefined,
                ]);

                mixpanel?.register(
                    {
                        "Scene ID": sceneId,
                        "Scene Title": sceneData.title,
                        "Scene Org": sceneData.organization,
                    },
                    { persistent: false },
                );
                mixpanel?.track_pageview({
                    "Scene ID": sceneId,
                    "Scene Title": sceneData.title,
                    "Scene Org": sceneData.organization,
                });

                const offlineWorkerState =
                    view.offline &&
                    (await view.manageOfflineStorage().catch((e) => {
                        console.warn("view.manageOfflineStorage():", e);
                        return undefined;
                    }));

                dispatch(
                    renderActions.initScene({
                        projectType: projectIsV2 ? ProjectType.V2 : ProjectType.V1,
                        projectV2Info: { ...projectV2, permissions },
                        tmZoneForCalc,
                        sceneData,
                        sceneConfig: octreeSceneConfig,
                        isVersionComparison,
                        initialCamera: sceneCamera ??
                            getDefaultCamera(
                                octreeSceneConfig.aabb
                                    ? [
                                          octreeSceneConfig.aabb.min[0],
                                          octreeSceneConfig.aabb.min[1],
                                          octreeSceneConfig.aabb.max[0],
                                          octreeSceneConfig.aabb.max[1],
                                      ]
                                    : projectV2?.bounds,
                            ) ?? {
                                position: view.renderState.camera.position,
                                rotation: view.renderState.camera.rotation,
                                fov: view.renderState.camera.fov,
                                kind: view.renderState.camera.kind,
                            },
                        deviceProfile,
                        assetsUrl: config.assetsUrl,
                    }),
                );

                if (!sceneCamera && octreeSceneConfig.boundingSphere.radius > DEFAULT_CAMERA_PINHOLE_FAR) {
                    // If scene is not configured - camera can be too far by default if project bounds are too big.
                    // Show a snackbar in this case.
                    dispatch(explorerActions.setCameraIsTooFar("initial"));
                }

                const groups: ObjectGroup[] = sceneData.objectGroups
                    .filter((group) => group.id && group.search)
                    .map((group) => ({
                        name: group.name,
                        id: group.id,
                        grouping: group.grouping ?? "",
                        color: group.color ?? ([1, 0, 0, 1] as VecRGBA),
                        opacity: group.opacity ?? 0,
                        search: group.search ?? [],
                        includeDescendants: group.includeDescendants ?? true,
                        status: group.selected
                            ? GroupStatus.Selected
                            : group.hidden
                              ? GroupStatus.Hidden
                              : group.frozen
                                ? GroupStatus.Frozen
                                : GroupStatus.None,
                        // NOTE(OLA): Pass IDs as undefined to be loaded when group is activated.
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ids: group.ids ? new Set(group.ids) : (undefined as any),
                    }));

                // Ensure frozen groups are loaded before rendering anything to not even put them into memory
                // (some scenes on some devices may crash upon loading because there's too much data)
                await fillGroupIds(
                    groups.filter((g) => g.status !== GroupStatus.None),
                    sceneId,
                );

                dispatchObjectGroups(objectGroupsActions.set(groups));
                dispatchHighlighted(
                    highlightActions.setColor(
                        sceneData.customProperties.explorerProjectState?.highlights?.primary?.color ??
                            sceneData.customProperties.highlights?.primary.color ?? [1, 0, 0, 1],
                    ),
                );
                dispatchHighlightCollections(
                    highlightCollectionsActions.setColor(
                        HighlightCollection.SecondaryHighlight,
                        sceneData.customProperties.explorerProjectState?.highlights?.secondary?.color ??
                            sceneData.customProperties.highlights?.secondary.color ?? [1, 1, 0, 1],
                    ),
                );

                window.document.title = `${sceneData.title} - Novorender`;
                const resizeObserver = new ResizeObserver((entries) => {
                    for (const entry of entries) {
                        dispatchGlobals(
                            explorerGlobalsActions.update({
                                size: {
                                    width: entry.contentRect.width,
                                    height: entry.contentRect.height,
                                },
                            }),
                        );
                    }
                });

                resizeObserver.observe(canvas);
                dispatchGlobals(
                    explorerGlobalsActions.update({
                        db: new AppObjectDB(db!, availableAndForbidden?.availableIds),
                        view: view,
                        scene: octreeSceneConfig,
                        offlineWorkerState,
                        availableObjectIds: availableAndForbidden?.availableIds,
                        forbiddenObjectIds:
                            availableAndForbidden && new Uint32Array(availableAndForbidden.forbiddenIds).sort(),
                    }),
                );

                view.run();

                if (!sceneCamera && db && isVersionComparison) {
                    // Don't await - let it load in the background
                    setupVersionComparisonPropertyTree(db, view);
                }

                await sleep(2000);
                dispatch(
                    renderActions.setSceneStatus({
                        status: AsyncStatus.Success,
                        data: undefined,
                    }),
                );
            } catch (e) {
                console.warn(e);
                if (e && typeof e === "object" && "error" in e) {
                    const { error, statusCode } = e as { error: string; statusCode: number };

                    if (
                        statusCode === 401 ||
                        statusCode === 403 ||
                        error === "Not authorized" ||
                        error === t("api.error.notAuthorized")
                    ) {
                        dispatch(
                            renderActions.setSceneStatus({
                                status: AsyncStatus.Error,
                                msg: ErrorKind.NOT_AUTHORIZED,
                            }),
                        );
                    } else if (
                        statusCode === 404 ||
                        error === "Scene not found" ||
                        error === t("api.error.sceneNotFound")
                    ) {
                        dispatch(
                            renderActions.setSceneStatus({
                                status: AsyncStatus.Error,
                                msg: ErrorKind.INVALID_SCENE,
                            }),
                        );
                    } else if (
                        statusCode === 410 ||
                        error === "Scene deleted" ||
                        error === t("api.error.sceneDeleted")
                    ) {
                        dispatch(
                            renderActions.setSceneStatus({
                                status: AsyncStatus.Error,
                                msg: ErrorKind.DELETED_SCENE,
                            }),
                        );
                    } else {
                        dispatch(
                            renderActions.setSceneStatus({
                                status: AsyncStatus.Error,
                                msg: navigator.onLine ? ErrorKind.UNKNOWN_ERROR : ErrorKind.OFFLINE_UNAVAILABLE,
                            }),
                        );
                    }
                } else if (e instanceof Error) {
                    if (e.message === "HTTP Error:404 404") {
                        dispatch(
                            renderActions.setSceneStatus({
                                status: AsyncStatus.Error,
                                msg: ErrorKind.EMPTY_PROJECT,
                            }),
                        );
                    } else {
                        dispatch(
                            renderActions.setSceneStatus({
                                status: AsyncStatus.Error,
                                msg: navigator.onLine ? ErrorKind.UNKNOWN_ERROR : ErrorKind.OFFLINE_UNAVAILABLE,
                                stack: e.stack
                                    ? e.stack
                                    : typeof e.cause === "string"
                                      ? e.cause
                                      : `${e.name}: ${e.message}`,
                            }),
                        );
                    }
                }
            }
        }
    }, [
        canvas,
        dispatch,
        sceneId,
        dispatchGlobals,
        dispatchObjectGroups,
        dispatchHighlighted,
        dispatchHighlightCollections,
        getProject,
        getModelTreePermissions,
        checkPermissions,
        config,
        fillGroupIds,
        setupVersionComparisonPropertyTree,
        t,
    ]);
}

function getStoredDeviceProfile(): (DeviceProfile & { debugProfile: true; tier: 0 }) | undefined {
    try {
        const debugProfile =
            new URLSearchParams(window.location.search).get("debugDeviceProfile") ?? localStorage["debugDeviceProfile"];

        if (debugProfile) {
            return { tier: 0, ...JSON.parse(debugProfile), debugProfile: true };
        }
    } catch (e) {
        console.warn(e);
    }
}

async function loadDeviceTier(): Promise<{ tier: -1 | DeviceProfile["tier"]; isMobile: boolean }> {
    try {
        const tiers = [0, 50, 75, 300];
        const tierResult = await getGPUTier({
            mobileTiers: tiers,
            desktopTiers: tiers,
        });
        const isApple = tierResult.device?.includes("apple") || false;
        const { isMobile, ...gpuTier } = tierResult;
        let { tier } = gpuTier;

        // GPU is obscured on apple mobile devices and the result is usually much worse than the actual device's GPU.
        if (isMobile && isApple) {
            tier = Math.max(1, tier);
        }

        // All RTX cards belong in tier 3+, but some such as A3000 are not benchmarked and returns tier 1.
        if (gpuTier.gpu && /RTX/gi.test(gpuTier.gpu)) {
            tier = Math.max(3, tier);
        }

        // Intel HD Graphics are given tier 2 for some reason, but belonging in tier 0.
        // " " before HD in regex to not catch UHD
        if (gpuTier.gpu && / HD Graphics /gi.test(gpuTier.gpu)) {
            tier = 0;
        }

        if (gpuTier.gpu && /Quadro/gi.test(gpuTier.gpu)) {
            tier = Math.max(2, tier);
        }

        return {
            tier: tier as DeviceProfile["tier"],
            isMobile: isMobile ?? false,
        };
    } catch (e) {
        console.warn(e);
        return {
            tier: -1,
            isMobile: false,
        };
    }
}

async function createView(canvas: HTMLCanvasElement, options?: { deviceProfile?: DeviceProfile }): Promise<View> {
    const deviceProfile = options?.deviceProfile ?? getDeviceProfile(0);
    const url = new URL("/novorender/api/", window.location.origin);

    const imports = await View.downloadImports({ baseUrl: url });
    const view = new View(canvas, deviceProfile, imports);
    view.controllers.flight.input.mouseMoveSensitivity = 4;
    view.controllers.flight.input.disableWheelOnShift = true;
    return view;
}

async function loadTmZoneForCalc(
    projectV2: ProjectInfo | undefined,
    tmZoneV1: string | undefined,
    sceneCenter: ReadonlyVec3,
) {
    if (projectV2) {
        if (projectV2.epsg) {
            // Try to use either .wkt or .proj4
            // Some conversions don't work in WKT, probably because proj4 doesn't support newer WKT versions
            // And some conversions don't work in proj4, because they require downloading additional
            const respWkt = await fetch(`https://epsg.io/${projectV2.epsg}.wkt`);
            if (respWkt.ok) {
                try {
                    const wkt = await respWkt.text();
                    const converted = tm2LatLon({
                        tmZone: wkt,
                        coords: sceneCenter,
                    });
                    if (!Number.isNaN(converted.latitude) && !Number.isNaN(converted.longitude)) {
                        return wkt;
                    }
                } catch (ex) {
                    console.warn("Error using WKT string for coordinate conversion, use proj4 instead", ex);
                }
            } else {
                console.warn(await respWkt.text());
            }

            // WKT failed, try proj4
            const respProj = await fetch(`https://epsg.io/${projectV2.epsg}.proj4`);
            if (respProj.ok) {
                return await respProj.text();
            } else {
                console.warn(await respProj.text());
            }
        }
    } else {
        return tmZoneV1;
    }
}

async function getAvailableAndForbiddenObjectIds(
    db: ObjectDB,
    availableResourcePaths: string[],
): Promise<undefined | { availableIds: Set<ObjectId>; forbiddenIds: Set<ObjectId> }> {
    // Remove child routes, because parent access implies access to children as well
    availableResourcePaths = availableResourcePaths.filter(
        (p1) => !availableResourcePaths.some((p2) => p2.length > p1.length && p2.startsWith(p1)),
    );

    const availableIds = new Set<ObjectId>();
    const descendantMap = new Map<ObjectId, ObjectId[]>();

    // Get all files/folders, their parents and descendants and make a set of IDs that are available.
    // This set can be used later to filter out e.g. search results.
    // NB: I've tried to search all paths at once with a single multivalue search, but it was much slower
    await Promise.all(
        availableResourcePaths.map(async (path) => {
            const [[obj]] = await iterateAsync({
                iterator: db.search({ parentPath: path, descentDepth: 0 }, undefined),
                count: 1,
            });

            if (!obj) {
                return;
            }
            availableIds.add(obj.id);

            await Promise.all([
                path.includes("/") &&
                    iterateAsync({
                        iterator: db.search({ parentPath: path, descentDepth: -100 }, undefined),
                        count: 100,
                    }).then(([parents]) => {
                        for (const parent of parents) {
                            availableIds.add(parent.id);
                        }
                    }),
                db
                    .descendants(obj, undefined)
                    .then((descendants) => {
                        for (const id of descendants) {
                            availableIds.add(id);
                        }
                        descendantMap.set(obj.id, descendants);
                    })
                    .catch((_) => undefined),
            ]);
        }),
    );

    // Fetch all root objects and corresponding descendants - this should cover all the object IDs available in the project -
    // and make a list of forbidden IDs, which is going to be passed to the highlights as the last group.
    // That's the best way (as opposed to using available IDs) of filtering.
    const forbiddenIds = new Set<ObjectId>();
    const [rootObjects] = await iterateAsync({
        iterator: db.search({ parentPath: "", descentDepth: 1 }, undefined),
        count: 500,
    });

    await Promise.all(
        rootObjects.map(async (obj) => {
            if (!availableIds.has(obj.id)) {
                forbiddenIds.add(obj.id);
            }

            const descendants = descendantMap.get(obj.id) ?? (await db.descendants(obj, undefined).catch((_) => []));
            for (const id of descendants) {
                if (!availableIds.has(id)) {
                    forbiddenIds.add(id);
                }
            }
        }),
    );

    if (forbiddenIds.size === 0) {
        return;
    }

    return { availableIds, forbiddenIds };
}
