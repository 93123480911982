import { DrawObject, DrawProduct, HorizontalAlignment, StationDrawObject, StationsDrawObject } from "@novorender/api";
import { vec2, vec3 } from "gl-matrix";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { Canvas2D } from "components";
import { featuresConfig } from "config/features";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { useHidden } from "contexts/hidden";
import { deviationsActions } from "features/deviations/deviationsSlice";
import { selectDeviationAlignmentDisplaySettings } from "features/deviations/selectors";
import {
    ColorSettings,
    drawLineStrip,
    drawPart,
    drawPoint,
    drawTexts,
    getCameraState,
    translateInteraction,
} from "features/engine2D";
import { CameraType, selectCameraType, selectClippingPlanes, selectMainObject, selectViewMode } from "features/render";
import { selectWidgets } from "slices/explorer";
import { AsyncStatus, ViewMode } from "types/misc";
import { vecToHex } from "utils/color";
import { closestPointOnLine } from "utils/math";
import { sleep } from "utils/time";

import { useCrossSection } from "./hooks/useCrossSection";
import { useFollowAlignment } from "./hooks/useFollowAlignment";
import { useGetAlignments } from "./hooks/useGetAlignments";
import { useSyncAlignmentView } from "./hooks/useSyncAlignmentView";
import { selectAlignmentId, selectHorizontalDisplaySettings, selectSelectedStation } from "./selectors";
import { selectAlignmentView } from "./selectors";
import { selectFollowObject } from "./selectors";
import { selectShowTracer } from "./selectors";
import { alignmentActions } from "./slice";
import { AlignmentView } from "./types";

const CURVATURE_COLOR_MAP = new Map<string, string>([
    ["line", "green"],
    ["arc", "red"],
    ["clothoid", "blue"],
]);

const ALIGNMENT_TEXT_SETTINGS = {
    type: "default" as const,
    pixelSize: 24,
    unit: "m",
};

const STATION_TEXT_SETTINGS = { ...ALIGNMENT_TEXT_SETTINGS, multiLine: true };

const STATION_LINE_COLOR_SETTINGS: ColorSettings = {
    lineColor: "rgba(0, 0, 0, 0.5)",
};

const DRAW_OBJECT_TEXT_SETTINGS = {
    type: "default" as const,
    unit: "m",
};

const DRAW_OBJECT_COLOR_SETTINGS = {
    lineColor: "#FFFFFF",
    pointColor: "#FFFFFF",
    fillColor: "#FFFFFF",
};

export function FollowAlignmentCanvas({
    renderFnRef,
    pointerPosRef,
    svg,
}: {
    pointerPosRef: MutableRefObject<Vec2>;
    renderFnRef: MutableRefObject<((moved: boolean, idleFrame: boolean) => void) | undefined>;
    svg: SVGSVGElement | null;
}) {
    const {
        state: { view, size },
    } = useExplorerGlobals();
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [tracerCtx, setTracerCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [markersCtx, setMarkersCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [alignmentCtx, setAlignmentCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [deviationsCtx, setDeviationsCtx] = useState<CanvasRenderingContext2D | null>(null);
    const dispatch = useAppDispatch();

    useSyncAlignmentView();

    const hidden = useHidden();
    const mainObj = useAppSelector(selectMainObject);
    const viewMode = useAppSelector(selectViewMode);
    const cameraType = useAppSelector(selectCameraType);
    const alignmentView = useAppSelector(selectAlignmentView);
    const isCrossSection = alignmentView === AlignmentView.OrthoCrossSection;
    const isTopDown = alignmentView === AlignmentView.OrthoTopDown;
    const displaySettings = useAppSelector(selectHorizontalDisplaySettings);

    const roadFilter = useCrossSection();
    const drawObjectsRef = useRef<DrawProduct[] | null>(null);
    const roadCrossSectionData = roadFilter.status === AsyncStatus.Success ? roadFilter.data : undefined;

    const showTracer = useAppSelector(selectShowTracer);
    const prevPointerPosRef = useRef<Vec2>([0, 0]);

    const deviationAlignmentDisplaySettings = useAppSelector(selectDeviationAlignmentDisplaySettings);
    const fpObj = useAppSelector(selectFollowObject);
    const clippingPlanes = useAppSelector(selectClippingPlanes);
    const plane = clippingPlanes.planes?.[0];

    const widgets = useAppSelector(selectWidgets);
    const isAlignmentVisible = useMemo(() => widgets.includes(featuresConfig.horizontalAlignment.key), [widgets]);

    const alignment = useAppSelector(selectAlignmentId);
    const prevAlignmentRef = useRef(alignment);
    const station = useAppSelector(selectSelectedStation);
    const prevStationRef = useRef(station);
    const alignments = useGetAlignments();
    const drawAlignmentRef = useRef<{ stations: StationsDrawObject; alignment: HorizontalAlignment } | null>(null);
    const drawStationRef = useRef<StationDrawObject | null>(null);

    const prevDrawProfileRef = useRef<typeof drawProfile | null>(null);
    const prevDrawCrossSectionRef = useRef<typeof drawCrossSection | null>(null);
    const prevDrawTracerRef = useRef<typeof drawTracer | null>(null);
    const prevDrawAlignmentRef = useRef<typeof drawAlignment | null>(null);

    useFollowAlignment();

    const drawCrossSection = useCallback(() => {
        if (!view?.measure || !ctx || !canvas) {
            return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (
            !roadCrossSectionData ||
            !(viewMode === ViewMode.FollowPath || viewMode === ViewMode.Deviations) ||
            isTopDown ||
            !drawObjectsRef.current
        ) {
            return;
        }

        const pixelWidth = 2;

        const cameraState = getCameraState(view.renderState.camera);
        for (const product of drawObjectsRef.current) {
            view.measure.draw.updateProduct(product);
            for (const object of product.objects) {
                for (const part of object.parts) {
                    drawPart(ctx, cameraState, part, DRAW_OBJECT_COLOR_SETTINGS, pixelWidth, DRAW_OBJECT_TEXT_SETTINGS);
                }
            }
        }
    }, [view, ctx, canvas, roadCrossSectionData, viewMode, isTopDown]);

    useEffect(() => {
        async function resetDraw() {
            await sleep(0);
            if (drawObjectsRef.current !== null) {
                drawObjectsRef.current = null;
                dispatch(alignmentActions.setHasTracer2dDrawProducts(false));
                prevDrawCrossSectionRef.current = null;
            }
        }
        resetDraw();
    }, [alignment, hidden, mainObj, plane, drawCrossSection, dispatch, station]);

    const drawTracer = useCallback(() => {
        if (!view || !tracerCtx || !canvas || !isCrossSection) {
            return;
        }

        tracerCtx.clearRect(0, 0, canvas.width, canvas.height);

        if (!drawObjectsRef.current || drawObjectsRef.current.length <= 1) {
            return;
        }

        const line = {
            start: vec2.fromValues(pointerPosRef.current[0], size.height),
            end: vec2.fromValues(pointerPosRef.current[0], 0),
        };

        const cameraState = getCameraState(view.renderState.camera);
        let skipPoints = showTracer === "normal";
        view.measure?.draw
            .getTraceDrawOject(
                drawObjectsRef.current,
                line,
                showTracer === "vertical" ? undefined : pointerPosRef.current,
            )
            ?.objects.forEach((obj) =>
                obj.parts.forEach((part) => {
                    drawPart(
                        tracerCtx,
                        cameraState,
                        part,
                        {
                            lineColor: "black",
                            displayAllPoints: !skipPoints,
                        },
                        2,
                        {
                            type: "default",
                        },
                    );
                    skipPoints = false;
                }),
            );
    }, [canvas, pointerPosRef, size, showTracer, tracerCtx, view, isCrossSection]);

    const drawAlignment = useCallback(async () => {
        if (!view?.measure || !alignmentCtx || !canvas) {
            return;
        }
        alignmentCtx.clearRect(0, 0, canvas.width, canvas.height);

        if (drawAlignmentRef.current === null || alignment !== prevAlignmentRef.current) {
            prevAlignmentRef.current = alignment;
            if (alignment === undefined || alignments.status !== AsyncStatus.Success) {
                return;
            }
            const selectedAlignment = alignments.data[alignment.name];
            if (!selectedAlignment) {
                return;
            }
            const drawStations = view.measure.road.getStationsDrawObject(
                selectedAlignment,
                displaySettings.stations.interval,
            );
            view.measure.road.updateStationsDrawObject(drawStations);
            const drawAlignment = view.measure.road.getHorizontalDrawItem(selectedAlignment, CURVATURE_COLOR_MAP);
            view.measure.road.updateHorizontalDrawItem(drawAlignment);
            drawAlignmentRef.current = { stations: drawStations, alignment: drawAlignment };
        } else {
            view.measure.road.updateStationsDrawObject(drawAlignmentRef.current.stations);
            view.measure.road.updateHorizontalDrawItem(drawAlignmentRef.current.alignment);
        }

        if (viewMode === ViewMode.Deviations) {
            try {
                dispatch(
                    deviationsActions.setClosestToCenterFollowPathPoint(
                        findClosestPointToScreenCenter(
                            drawAlignmentRef.current.alignment.segment,
                            window.innerWidth,
                            window.innerHeight,
                        ),
                    ),
                );
            } catch {
                console.warn("Error updating closest centerline point to screen center");
            }
        }

        const cameraState = getCameraState(view.renderState.camera);

        const curveColorSettings: ColorSettings =
            alignments?.status === AsyncStatus.Success &&
            alignment &&
            alignments.data[alignment.name]?.tesselatedSegment
                ? { lineColor: "#ead610" }
                : {};
        for (const part of drawAlignmentRef.current.alignment.segment.parts) {
            drawPart(alignmentCtx, cameraState, part, curveColorSettings, 4, ALIGNMENT_TEXT_SETTINGS);
        }
        for (const stationLine of drawAlignmentRef.current.stations.stationLines) {
            drawPart(alignmentCtx, cameraState, stationLine, STATION_LINE_COLOR_SETTINGS, 4, ALIGNMENT_TEXT_SETTINGS);
        }
        drawPart(
            alignmentCtx,
            cameraState,
            { ...drawAlignmentRef.current.stations.stationInfo, angles2D: undefined },
            {},
            4,
            ALIGNMENT_TEXT_SETTINGS,
        );

        if (station) {
            if (drawStationRef.current && station === prevStationRef.current) {
                view.measure.road.updateStationDrawObject(drawStationRef.current);
            } else {
                prevStationRef.current = station;
                drawStationRef.current = view.measure.road.getStationDrawObject(station, true);
            }
            for (const p of drawStationRef.current.info.parts) {
                drawPart(alignmentCtx, cameraState, p, {}, 4, STATION_TEXT_SETTINGS);
            }
        }
    }, [
        dispatch,
        alignmentCtx,
        view,
        viewMode,
        canvas,
        alignment,
        alignments,
        station,
        displaySettings.stations.interval,
    ]);

    const drawProfile = useCallback(async () => {
        if (!svg) {
            return;
        }

        if (!view?.measure || !markersCtx || !canvas || !station) {
            removeMarkers(svg);
            return;
        }

        markersCtx.clearRect(0, 0, canvas.width, canvas.height);
        const pt = view.convert.worldSpaceToScreenSpace([station.point], { round: false })[0];

        if (!pt) {
            removeMarkers(svg);
            return;
        }

        if (view.renderState.camera.far < 1) {
            drawPoint(markersCtx, pt, "black");
            translateInteraction(svg.children.namedItem(`followPlus`), vec2.fromValues(pt[0] + 50, pt[1]));
            translateInteraction(svg.children.namedItem(`followMinus`), vec2.fromValues(pt[0] - 50, pt[1]));
            translateInteraction(svg.children.namedItem(`followInfo`), vec2.fromValues(pt[0], pt[1] - 55));
            translateInteraction(
                svg.children.namedItem(`followClose`),
                isAlignmentVisible ? undefined : vec2.fromValues(pt[0], pt[1] + 55),
            );
        } else if (view.renderState.clipping.planes.length > 0) {
            const plane = view.renderState.clipping.planes[0].normalOffset;
            const normal = vec3.fromValues(plane[0], plane[1], plane[2]);
            let up = vec3.fromValues(0, 0, 1);
            if (Math.abs(vec3.dot(normal, up)) === 1) {
                up = vec3.fromValues(0, 1, 0);
            }
            const right = vec3.cross(vec3.create(), up, normal);
            vec3.normalize(right, right);
            const pt = view.convert.worldSpaceToScreenSpace([
                station.point,
                vec3.scaleAndAdd(vec3.create(), station.point, right, 10), //Scale by 10 to avoid jitter
            ]);
            if (pt[0] && pt[1]) {
                const dir = vec2.sub(vec2.create(), pt[1], pt[0]);
                vec2.normalize(dir, dir);
                translateInteraction(
                    svg.children.namedItem(`followPlus`),
                    vec2.scaleAndAdd(vec2.create(), pt[0], dir, 40),
                );
                translateInteraction(
                    svg.children.namedItem(`followMinus`),
                    vec2.scaleAndAdd(vec2.create(), pt[0], dir, -40),
                );
                translateInteraction(
                    svg.children.namedItem(`followInfo`),
                    vec2.scaleAndAdd(
                        vec2.create(),
                        pt[0],
                        dir[0] > 0 ? vec2.fromValues(-dir[1], dir[0]) : vec2.fromValues(dir[1], -dir[0]),
                        -45,
                    ),
                );
                translateInteraction(
                    svg.children.namedItem(`followClose`),
                    isAlignmentVisible
                        ? undefined
                        : vec2.scaleAndAdd(
                              vec2.create(),
                              pt[0],
                              dir[0] <= 0 ? vec2.fromValues(-dir[1], dir[0]) : vec2.fromValues(dir[1], -dir[0]),
                              -45,
                          ),
                );
            } else {
                removeMarkers(svg);
            }
            if (!fpObj) {
                //Special case for old bookmarks, they do not have the possibility to step without opening the windget first
                translateInteraction(svg.children.namedItem(`followPlus`), undefined);
                translateInteraction(svg.children.namedItem(`followMinus`), undefined);
            }
        } else {
            removeMarkers(svg);
        }
    }, [canvas, station, markersCtx, view, svg, fpObj, isAlignmentVisible]);

    const deviationsDrawId = useRef(0);
    const drawDeviations = useCallback(
        async (idleFrame: boolean) => {
            if (!view || !deviationsCtx || !canvas || !station) {
                return;
            }

            const id = ++deviationsDrawId.current;

            if (!idleFrame) {
                deviationsCtx.clearRect(0, 0, canvas.width, canvas.height);
                return;
            }

            const centerPoint2d = view.measure?.draw.toMarkerPoints([station.point])[0];

            if (!centerPoint2d) {
                return;
            }
            const deviations = await view.inspectDeviations({
                deviationPrioritization: deviationAlignmentDisplaySettings.prioritization,
                projection: { centerPoint2d, centerPoint3d: station.point },
                generateLine: deviationAlignmentDisplaySettings.line,
            });

            if (id !== deviationsDrawId.current) {
                return;
            }

            deviationsCtx.clearRect(0, 0, canvas.width, canvas.height);

            if (!deviations) {
                return;
            }

            const [pts2d, labels] = deviations.labels.reduce(
                (prev, curr) => {
                    prev[0].push(curr.position);
                    prev[1].push(curr.deviation);

                    return prev;
                },
                [[] as Vec2[], [] as string[]],
            );
            dispatch(deviationsActions.setRightmost2dDeviationCoordinate(Math.max(...pts2d.map((p) => p[0]))));

            drawTexts(deviationsCtx, pts2d, labels, 20);

            if (deviations.line) {
                drawLineStrip(deviationsCtx, deviations.line, vecToHex(deviationAlignmentDisplaySettings.lineColor));
            }
        },
        [canvas, station, deviationsCtx, deviationAlignmentDisplaySettings, view, dispatch],
    );

    useEffect(() => {
        prevDrawCrossSectionRef.current = null;
        prevDrawTracerRef.current = null;
        prevDrawAlignmentRef.current = null;
        prevDrawProfileRef.current = null;
    }, [size]);

    // Ensure that alignment +/- buttons are shown immediately
    // after adding a clipping plane
    useEffect(() => {
        const timeout = setTimeout(() => {
            prevDrawProfileRef.current = null;
        });
        return () => clearTimeout(timeout);
    }, [clippingPlanes]);

    useEffect(() => {
        drawAlignmentRef.current = null;
        prevDrawAlignmentRef.current = null;
    }, [alignment, displaySettings]);

    useEffect(() => {
        drawStationRef.current = null;
        prevDrawAlignmentRef.current = null;
    }, [station]);

    useEffect(() => {
        renderFnRef.current = animate;
        return () => (renderFnRef.current = undefined);

        async function animate(moved: boolean, idleFrame: boolean): Promise<void> {
            if (!view) {
                return;
            }
            if (alignmentCtx && (moved || drawAlignment !== prevDrawAlignmentRef.current)) {
                drawAlignment();
                prevDrawAlignmentRef.current = drawAlignment;
            }

            if (!ctx) {
                return;
            }
            if (view.renderState.clipping.planes.length > 0) {
                if (idleFrame && drawObjectsRef.current == null) {
                    drawObjectsRef.current = view.getOutlineDrawObjects(
                        "clipping",
                        0,
                        undefined,
                        {
                            generateLengthLabels: true,
                            generateSlope: true,
                            closed: false,
                            angles: false,
                        },
                        roadCrossSectionData,
                    );

                    dispatch(alignmentActions.setHasTracer2dDrawProducts(drawObjectsRef.current.length > 0));
                    drawCrossSection();
                } else if (moved || drawCrossSection !== prevDrawCrossSectionRef.current) {
                    drawCrossSection();
                }
            } else if (canvas) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
            prevDrawCrossSectionRef.current = drawCrossSection;

            if (
                moved ||
                drawTracer !== prevDrawTracerRef.current ||
                !vec2.exactEquals(prevPointerPosRef.current, pointerPosRef.current)
            ) {
                drawTracer();
                prevPointerPosRef.current = [...pointerPosRef.current];
                prevDrawTracerRef.current = drawTracer;
            }

            if (moved || drawProfile !== prevDrawProfileRef.current) {
                drawProfile();
                prevDrawProfileRef.current = drawProfile;
            }

            if (moved || idleFrame) {
                drawDeviations(idleFrame);
            }
        }
    }, [
        view,
        renderFnRef,
        drawCrossSection,
        drawTracer,
        pointerPosRef,
        showTracer,
        drawProfile,
        drawAlignment,
        drawDeviations,
        roadCrossSectionData,
        ctx,
        alignmentCtx,
        dispatch,
        canvas,
    ]);

    const isFollowPathOrDeviations = viewMode === ViewMode.FollowPath || viewMode === ViewMode.Deviations;

    const canDrawRoad = roadCrossSectionData && isFollowPathOrDeviations;
    const canDrawTracer =
        showTracer != "off" &&
        cameraType === CameraType.Orthographic &&
        isFollowPathOrDeviations &&
        drawObjectsRef.current &&
        drawObjectsRef.current.length >= 2;
    const canDrawProfile = isFollowPathOrDeviations && station;
    const canDrawDeviations =
        isFollowPathOrDeviations &&
        cameraType == CameraType.Orthographic &&
        alignmentView === AlignmentView.OrthoCrossSection &&
        station;
    const canDrawAlignment = alignment !== undefined && alignments.status == AsyncStatus.Success;

    return (
        <>
            {canDrawAlignment && (
                <Canvas2D
                    id="alignment"
                    data-include-snapshot
                    ref={(el) => {
                        setCanvas(el);
                        setAlignmentCtx(el?.getContext("2d") ?? null);
                    }}
                    width={size.width}
                    height={size.height}
                />
            )}
            {canDrawRoad && (
                <Canvas2D
                    id="follow-path-road-canvas"
                    data-include-snapshot
                    ref={(el) => {
                        setCanvas(el);
                        setCtx(el?.getContext("2d") ?? null);
                    }}
                    width={size.width}
                    height={size.height}
                />
            )}
            {canDrawTracer && (
                <Canvas2D
                    id="follow-path-tracer-canvas"
                    data-include-snapshot
                    ref={(el) => {
                        setCanvas(el);
                        setTracerCtx(el?.getContext("2d") ?? null);
                    }}
                    width={size.width}
                    height={size.height}
                />
            )}
            {canDrawProfile && (
                <Canvas2D
                    id="follow-path-profile-canvas"
                    data-include-snapshot
                    ref={(el) => {
                        setCanvas(el);
                        setMarkersCtx(el?.getContext("2d") ?? null);
                    }}
                    width={size.width}
                    height={size.height}
                />
            )}
            {canDrawDeviations && (
                <Canvas2D
                    id="follow-path-deviations-canvas"
                    data-include-snapshot
                    ref={(el) => {
                        setCanvas(el);
                        setDeviationsCtx(el?.getContext("2d") ?? null);
                    }}
                    width={size.width}
                    height={size.height}
                />
            )}
        </>
    );
}

function removeMarkers(svg: SVGSVGElement) {
    translateInteraction(svg.children.namedItem(`followPlus`), undefined);
    translateInteraction(svg.children.namedItem(`followMinus`), undefined);
    translateInteraction(svg.children.namedItem(`followInfo`), undefined);
    translateInteraction(svg.children.namedItem(`followClose`), undefined);
}

function findClosestPointToScreenCenter(obj: DrawObject, screenWidth: number, screenHeight: number) {
    const lines = obj.parts[0];
    if (!lines?.vertices2D || lines.vertices2D.length === 0) {
        return;
    }

    const center = vec2.fromValues(screenWidth / 2, screenHeight / 2);
    let closest1 = vec2.create();
    let minDistSq1 = Number.MAX_SAFE_INTEGER;
    let closest2 = vec2.create();
    let minDistSq2 = Number.MAX_SAFE_INTEGER;

    for (const v of lines.vertices2D) {
        const distSq = vec2.sqrDist(v, center);
        if (distSq < minDistSq1) {
            if (minDistSq1 < minDistSq2) {
                minDistSq2 = minDistSq1;
                closest2 = closest1;
            }
            minDistSq1 = distSq;
            closest1 = v;
        } else if (distSq < minDistSq2) {
            minDistSq2 = distSq;
            closest2 = v;
        }
    }

    if (closest1[0] === 0 && closest1[1] === 0) {
        return;
    } else if (vec2.equals(closest1, closest2) || (closest2[0] === 0 && closest2[1] === 0)) {
        return closest1;
    } else {
        return closestPointOnLine(vec2.create(), center, closest1, closest2).point ?? closest1;
    }
}
